<template>
  <div class="CaseMainFinance">
    <div class="financePart">
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">合同预估</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">{{topDataAccount.predictAmount}}</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">已收金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">{{topDataAccount.receivedAmount}}</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">已开票金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">{{topDataAccount.invoicedAmount}}</div>
        </div>
      </div>
      <div class="financItem">
        <div class="title">
          <div class="iconfont iconqian"></div>
          <div class="titleText">支出金额</div>
        </div>
        <div class="count">
          <div class="iconfont iconqian1"></div>
          <div class="countNumber">{{topDataAccount.payAmount}}</div>
        </div>
      </div>
    </div>
    <div class="financeList">
      <div class="leftMenu">
        <div :class='["menuIcon",current===idx?"menuOn":""]'
        v-for='(item,idx) in leftNav' :key='item'
        @click='toggleLeftNav(idx)'>
          <div class="iconfont iconshouru"></div>
          <div class="iconText">{{item}}</div>
          <div class="barIcon"></div>
        </div>
      </div>
      <div class="rightContent">
        <div class="topBtn">
          <el-button type='primary' icon='el-icon-plus' size="small" round @click='addOppBtn'>添加</el-button>
        </div>
        <div class="rightTable">
          <el-table
           v-show="current==0"
            :data="tableData_zc"
            style="width: 100%">
            <el-table-column
              prop="chargeName"
              label="名称"
              width="180">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额（元）"
              width="180">
            </el-table-column>
            <el-table-column
              prop="payTypeName"
              label="付款方式">
            </el-table-column>
            <el-table-column
              prop="payDate"
              label="支付时间">
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              width="100"
              align='center'>
              <template #default="scope">
                <el-dropdown trigger="click" placement="bottom" @command="more($event,scope)">
                <div class="iconfont icongengduo"></div>
                <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item command="1">查看</el-dropdown-item>
                      <el-dropdown-item command="2">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-show="current==1"
            :data="tableData_sr"
            style="width: 100%">
            <el-table-column
              prop="chargeName"
              label="名称"
              width="180">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额（元）"
              width="180">
            </el-table-column>
            <el-table-column
              prop="payTypeName"
              label="收款方式">
            </el-table-column>
            <el-table-column
              label="收款确认">
              <template #default="scope">
                <span :class="scope.row.isCollection==='已确认'?'mainColor':''">{{scope.row.isCollection}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="开票状态">
              <template #default="scope">
                <span :class="scope.row.billingStatus==='已开票'?'mainColor':''">{{scope.row.billingStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              width="100"
              align='center'>
              <template #default="scope">
                <el-dropdown trigger="click" placement="bottom" @command="more2($event,scope)">
                <div class="iconfont icongengduo"></div>
                <template #dropdown>
                  <el-dropdown-menu>
                      <el-dropdown-item command="1">查看</el-dropdown-item>
                      <el-dropdown-item command="2">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 收款确认 -->
    <myDialog
    title='收款确认'
    :isShow='openOpp_skqr'
    :closeDialog='closeDialog_skqr'>
      <div class="addFormBox">
        <el-form
        ref='form_skqr'
          :model="form_skqr" 
          label-width="100px">
            <el-form-item label="款项金额">
              <div class='kxje'>
                <span>{{form_skqr.chargeName}}</span><span>{{form_skqr.amount}}元</span>
              </div>
            </el-form-item>
            <el-form-item label="付款方名称" prop='payerName'
            :rules="{
              required: true, message: '付款方名称不能为空', trigger: ['blur','change']
            }">
              <el-input size='small' style='width:350px'
                placeholder="请输入付款方名称"
                v-model="form_skqr.payerName"></el-input>
            </el-form-item>
            <el-form-item label="收款方式" prop='payerType'>
              <el-select v-model="form_skqr.payerType" placeholder="请选择" size='small' style='width:350px'>
                <el-option
                  v-for="item in accountType"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款时间" prop='payerDate'>
              <el-date-picker size='small' style='width:350px'
                v-model="form_skqr.payerDate"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item 
            label="审批人" 
            prop='reviewUserId'
            :rules="{
              required: true, message: '审批人不能为空', trigger: ['blur','change']
            }"
            >
              <el-select
                v-model="form_skqr.reviewUserId"
                style='width:350px'
                size='small'
                filterable
                remote
                reserve-keyword
                placeholder="请输入审批人"
                :remote-method="remoteMethodspr"
                :loading="sprloading">
                <el-option
                  v-for="item in reviewUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item props='fileIdList' label="附件" class='applyForm_kpUpload'
            :rules="{
              required: true, message: '附件不能为空', trigger: ['blur','change']
            }">
              <el-upload :disabled='uploadLoading'
                ref='applyForm_kpUpload'
                class="uploadComp"
                action="#"
                :limit='5'
                :on-change="handleChangeUpload"
                :http-request='myUploadSubmit'
                :on-exceed='onExceed'
                :on-error='onErrorUpload'
                :before-remove="beforeRemove"
                accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                <el-button size="small" type="primary" 
                icon="el-icon-plus" :loading="uploadLoading">上传</el-button>
                <template #tip>
                  <div class="errorBox" v-show='upLoadIsError'>
                    <el-alert
                      :title="errorText"
                      type="error"
                      show-icon
                      @close='closeAlert'>
                    </el-alert>
                  </div>
                  <div class="textLine">
                    <div>可上传<span>5个附件</span></div>
                    <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                  </div>
                </template>
              </el-upload>
            </el-form-item>
            <el-form-item label="备注" prop='applyRemark'>
              <el-input size='small' style='width:350px' type='textarea'
              v-model="form_skqr.applyRemark" clearable placeholder="请输入备注内容"></el-input>
            </el-form-item>
        </el-form>
        <div class="btnFooter">
          <el-button size='small' type="primary" plain
          @click='closeDialog_skqr'>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='submitDialog_skqr'>提交</el-button>
        </div>
      </div>
    </myDialog>
    <!-- 开票申请 -->
    <myDialog
    title='开票申请'
    :isShow='openAddOpp_kp'
    :closeDialog='closeDialog_kp'>
      <div class="addFormBox">
        <el-form
        ref='applyForm_kp'
          :model="applyForm_kp" 
          label-width="100px">
            <el-form-item label="款项金额">
              <div class='kxje'>
                <span>{{applyForm_kp.chargeName}}</span><span>{{applyForm_kp.amount}}元</span>
              </div>
            </el-form-item>
            <el-form-item label="发票类型" prop='type'
            :rules="{
              required: true, message: '发票类型不能为空', trigger: ['blur','change']
            }">
              <el-select size='small' style='width:350px'
              v-model="applyForm_kp.type" placeholder="请选择">
                <el-option
                  v-for="item in fpTypes"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="applyForm_kp_inlinBox">
              <el-form-item label="抬头" prop='titleType'
              :rules="{
                required: true, message: '抬头不能为空', trigger: ['blur','change']
              }">
                <el-select size='small'
                v-model="applyForm_kp.titleType" placeholder="请选择" style='width:150px'>
                  <el-option
                  v-for="item in fpttTypes"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop='titleContent' class='mfl0'>
                <el-input size='small' style='width:200px'
                  placeholder="请输入内容"
                  v-model="applyForm_kp.titleContent"></el-input>
              </el-form-item>
            </div>
            <el-form-item 
            label="审批人" 
            prop='reviewUserId'
            :rules="{
              required: true, message: '审批人不能为空', trigger: ['blur','change']
            }"
            >
              <el-select
                v-model="applyForm_kp.reviewUserId"
                style='width:350px'
                size='small'
                filterable
                remote
                reserve-keyword
                placeholder="请输入审批人"
                :remote-method="remoteMethodspr2"
                :loading="sprloading">
                <el-option
                  v-for="item in reviewUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item 
            :rules="{
              required: true, message: '附件不能为空', trigger: ['blur','change']
            }"
            label="附件" prop='fileIdList' class='applyForm_kpUpload'>
              <el-upload :disabled='uploadLoading'
                ref='applyForm_kpUpload'
                class="uploadComp"
                action="#"
                multiple
                :limit='5'
                :on-change="handleChangeUpload"
                :http-request='myUploadSubmit2'
                :on-exceed='onExceed'
                :on-error='onErrorUpload'
                :before-remove="beforeRemove2"
                accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                <el-button size="small" type="primary" 
                icon="el-icon-plus" :loading='uploadLoading'>上传</el-button>
                <template #tip>
                  <div class="errorBox" v-show='upLoadIsError'>
                    <el-alert
                      :title="errorText"
                      type="error"
                      show-icon
                      @close='closeAlert'>
                    </el-alert>
                  </div>
                  <div class="textLine">
                    <div>可上传<span>5个附件</span></div>
                    <div>每个附件大小不得超过8M，支持格式：“jpg”,“png”,“doc”,“pdf”,“xls”</div>
                  </div>
                </template>
              </el-upload>
            </el-form-item>
            <el-form-item label="备注" prop='applyRemark'>
              <el-input size='small' style='width:350px' type='textarea'
              v-model="applyForm_kp.applyRemark" clearable placeholder="请输入备注内容"></el-input>
            </el-form-item>
        </el-form>
        <div class="btnFooter">
          <el-button size='small' type="primary" plain
          @click='closeDialog_kp'>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='submitApplyForm_kp'>提交</el-button>
        </div>
      </div>
    </myDialog>
    <!-- 添加收入 / 收入详情-->
    <myDialog
    :title='title_sr'
    :isShow='openAddOpp_sr'
    :closeDialog='closeDialog_sr'>
      <div class="addFormBox">
        <el-form
        ref='addForm_sr'
          :model="addForm_sr" 
          label-width="100px">
            <el-form-item label="名称" prop='chargeName'
            :rules="{
              required: true, message: '名称不能为空', trigger: 'blur'
            }">
              <el-input :disabled='scIsAdd===2' size='small' style='width:350px'
              v-model="addForm_sr.chargeName" clearable placeholder="请输入收费名称，如：律师费"></el-input>
            </el-form-item>
            <div class="inlineBox">
              <el-form-item label="金额" prop='payType' 
              :rules="{
                required: true, message: '请选择收入方式', trigger:['blur','change']
              }">
                <el-select :disabled='scIsAdd===2' size='small' style='width:150px'
                v-model="addForm_sr.payType">
                  <el-option
                    v-for="item in accountType"
                   :key="item.key"
                  :label="item.value"
                  :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop='amount' class="inlineItem">
                <el-input :disabled='scIsAdd===2' style='width:200px' size='small' placeholder="请输入金额" 
                v-model="addForm_sr.amount">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item label="备注" prop='billRemark'>
              <el-input :disabled='scIsAdd===2' size='small' style='width:350px' type='textarea'
              v-model="addForm_sr.billRemark" clearable placeholder="请输入备注内容"></el-input>
            </el-form-item>
            <!-- // 收入判断：0编辑，1新增,2查看 -->
            <el-form-item label="收款确认" v-if='scIsAdd===2'>
              <div class="sr_btnGrowBox">
                <div>{{addForm_sr.confirmStatusName}}</div>
                <!-- 未开票未确认为1或者审核不通过状态去显示“去确认” -->
                <div class="btn" v-if='addForm_sr.confirmApplyStatus'
                @click='apply_openOpp_skqr'>去确认</div>
                <div 
                  :class="[addForm_sr.confirmApplyStatusName=='待审批'?'details':'',addForm_sr.confirmApplyStatusName=='通过'?'pass':'',addForm_sr.confirmApplyStatusName=='不通过'?'error':'']"
                  v-if='addForm_sr.confirmApplyStatusName'
                  @click='applyDetils(addForm_sr.applyIdSK)'>
                  {{addForm_sr.confirmApplyStatusName.includes('审批')?addForm_sr.confirmApplyStatusName:'审批'+addForm_sr.confirmApplyStatusName}}（点击查看）</div>
                </div>
            </el-form-item>
            <el-form-item label="开票" v-if='scIsAdd===2'>
              <div class="sr_btnGrowBox">
                <div>{{addForm_sr.invoiceStatusName}}</div>
                <!-- 未开票未确认为1或者审核不通过状态去显示“去开票” -->
                <div class="btn" v-if='addForm_sr.invoiceApplyStatus'
                @click='apply_openAddOpp_kp'>去开票</div>
                <div 
                :class="[addForm_sr.invoiceApplyStatusName=='待审批'?'details':'',addForm_sr.invoiceApplyStatusName=='通过'?'pass':'',addForm_sr.invoiceApplyStatusName=='不通过'?'error':'']"
                v-if='addForm_sr.invoiceApplyStatusName'
                @click='applyDetils(addForm_sr.applyIdKP)'
                >
                  {{addForm_sr.invoiceApplyStatusName.includes('审批')?addForm_sr.invoiceApplyStatusName:'审批'+addForm_sr.invoiceApplyStatusName}}（点击查看）</div>
                </div>
            </el-form-item>
        </el-form>
        <div class="btnFooter" v-if="scIsAdd===2&&(!addForm_sr.isApplySK)&&(!addForm_sr.isApplyKP)">
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='scIsAdd=0'>编辑</el-button>
        </div>
        <div class="btnFooter" v-if="scIsAdd===1||scIsAdd===0">
          <el-button size='small' type="primary" plain
          @click='closeDialog_sr'>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='saveDialog_sr'>保存</el-button>
        </div>
      </div>
    </myDialog>
    <!-- 添加支出 / 支出详情-->
    <myDialog
    :title='title_zc'
    :isShow='openAddOpp_zc'
    :closeDialog='closeDialog_zc'>
      <div class="addFormBox">
        <el-form 
          ref='addForm_zc'
          :model="addForm_zc" 
          label-width="100px">
            <el-form-item label="名称" prop='chargeName'
            :rules="{
              required: true, message: '名称不能为空', trigger: 'blur'
            }">
              <el-input :disabled='zcIsAdd===2' size='small' style='width:350px'
              v-model="addForm_zc.chargeName" clearable placeholder="请输入收费名称，如：律师费"></el-input>
            </el-form-item>
            <el-form-item label="金额" prop='amount'
            :rules="{
              required: true, message: '金额不能为空', trigger: 'blur'
            }">
              <el-input :disabled='zcIsAdd===2' style='width:350px' size='small' placeholder="请输入金额" 
                v-model="addForm_zc.amount"><template #append>元</template></el-input>
            </el-form-item>
            <el-form-item label="付款方式" prop='payType'>
              <el-select :disabled='zcIsAdd===2' size='small' style='width:350px'
              v-model="addForm_zc.payType" placeholder="请选择付款方式">
                <el-option
                  v-for="item in accountType"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付时间" prop='payDate'>
              <el-date-picker :disabled='zcIsAdd===2' size='small' style='width:350px'
                v-model="addForm_zc.payDate"
                type="datetime"
                placeholder="请选择日期支付时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop='billRemark'>
              <el-input :disabled='zcIsAdd===2' size='small' style='width:350px' type='textarea'
              v-model="addForm_zc.billRemark" clearable placeholder="请输入备注内容"></el-input>
            </el-form-item>
        </el-form>
        <div class="btnFooter" v-if="zcIsAdd===2">
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='zcIsAdd=0'>编辑</el-button>
        </div>
        <div class="btnFooter" v-if="zcIsAdd===1||zcIsAdd===0">
          <el-button size='small' type="primary" plain
          @click='closeDialog_zc'>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='saveDialog_zc'>保存</el-button>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import moment from 'moment';
import {saveCharge,getRealChargePage,getChargeInfo,updateCharge,
saveApply,tChargeDelete,getReviewUserList,uploadFile} from '@/api';
import {PAGE_SIZE,PAGE_SIZES} from '@/utils/const.js'
export default defineComponent({
  name:"CaseMainFinance",
  data(){
    return {
      topDataAccount:{
        invoicedAmount:'',
        payAmount:'',
        predictAmount:'',
        receivedAmount:''
      },
      currentPage:1,
      pageSizes:PAGE_SIZES,
      pageSize:PAGE_SIZE,
      currentPage_zc:1,
      currentPage_sr:1,
      pageSizes_zc:PAGE_SIZES,
      pageSizes_sr:PAGE_SIZES,
      pageSize_zc:PAGE_SIZE,
      pageSize_sr:PAGE_SIZE,
      total:1,
      total_sr:1,
      total_zc:1,
      uploadLoading:false,
      title_sr:'',
      title_zc:'',
      currentDetailsId:'',
      sprloading:false,
      leftNav:['支出','收入'],
      current:0,
      tableData_zc: [],
      tableData_sr: [],
      openAddOpp_sr:false,
      openAddOpp_zc:false,
      addOppTitle:"添加收入",
      // 支付、付款方式下拉
      accountType:[],
      // 支出
      addForm_zc:{
        amount:'',
        billRemark:'',
        chargeName:'',
        payDate:'',
        payType:'',
        relId:this.$route.params.caseId,
        relType:2,
        type:1
      },
      // 收入
      addForm_sr:{
        chargeName:'',
        amount:'',
        billRemark:'',
        payType:'',
        relId:this.$route.params.caseId,
        relType:2,
        type:0,
        skqr:'',
        kp:''
      },
      // 开票申请
      openAddOpp_kp:false,
      applyForm_kp:{
        fileList:[]
      },
      fpTypes:[],
      sprList:[],
      upLoadIsError:false,
      errorText:'',
      // 收款确认
      openOpp_skqr:false,
      form_skqr:{},
      // 支出判断：0编辑，1新增,2查看
      zcIsAdd:'',
      // 收入判断：0编辑，1新增,2查看
      scIsAdd:'',
      // 收款审核人
      getReviewUserList4:[
        {
          label:'测试写死',
          value:666
        }
      ],
      // 开票审核人
      getReviewUserList5:[
        {
          label:'测试写死',
          value:666
        }
      ],
      reviewUserList:[
        {
          label:'测试写死',
          value:666
        }
      ],
      fpttTypes:[],
    }
  },
  async created(){
    this.getList({
      pageNo:1,
      pageSize:PAGE_SIZE,
      realId:this.$route.params.caseId,
      relType:2
    });
    // console.log('PAGE_SIZES:',PAGE_SIZES,this.pageSizes)
    this.getReviewUserListApi4();
    this.getReviewUserListApi5();
    // 支付、付款方式下拉
    this.accountType = await this.$store.dispatch('getDataDictionary',10012);
    this.fpTypes = await this.$store.dispatch('getDataDictionary',10037);
    this.fpttTypes = await this.$store.dispatch('getDataDictionary',10023);
  },
  components:{
    myDialog
  },
  methods:{
    toggleLeftNav(idx){
      this.current = idx;
      if(this.current===0){
        // 支出
        this.currentPage = this.currentPage_zc;
        this.pageSize = this.pageSize_zc;
        this.total = this.total_zc
      }
      if(this.current===1){
        // 收入
        this.currentPage = this.currentPage_sr;
        this.pageSize = this.pageSize_sr;
        this.total = this.total_sr
      };
    },
    handleSizeChange(v){
      if(this.current===0){
        // 支出
        this.pageSize_zc = v;
        this.pageSize = this.pageSize_zc;
        this.currentPage = this.currentPage_zc;
      }
      if(this.current===1){
        // 收入
        this.pageSize_sr = v;
        this.pageSize = this.pageSize_sr;
        this.currentPage = this.currentPage_sr;
      };
      console.log('更新列表:handleSizeChange')
      this.getList({
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        realId:this.$route.params.caseId,
        relType:2
      });
    },
    handleCurrentChange(v){
      if(this.current===0){
        // 支出
        this.currentPage_zc = v;
        this.pageSize = this.pageSize_zc;
        this.currentPage = this.currentPage_zc;
      }
      if(this.current===1){
        // 收入
        this.currentPage_sr = v;
         this.pageSize = this.pageSize_sr;
        this.currentPage = this.currentPage_sr;
      };
      console.log('更新列表:handleCurrentChange')
      this.getList({
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        realId:this.$route.params.caseId,
        relType:2
      });
    },
    remoteMethodspr(v){
      this.sprloading = true;
      if(this.getReviewUserList4.length){
        this.reviewUserList = this.getReviewUserList4.filter(item=>{
          return item.userName.includes(v)
        });
        this.sprloading = false;
      }else{
        this.reviewUserList = [];
        this.sprloading = false;
      }
    },
    remoteMethodspr2(v){
      this.sprloading = true;
      if(this.getReviewUserList5.length){
        this.reviewUserList = this.getReviewUserList5.filter(item=>{
          return item.userName.includes(v)
        });
        this.sprloading = false;
      }else{
        this.reviewUserList = [];
        this.sprloading = false;
      }
    },
    // 获取全部审批人
    // reviewType:4 收款,5 开票
    async getReviewUserListApi4(){
      let res = await getReviewUserList({
        reviewType:4
      });
      if(res&&res.code==200){
        this.getReviewUserList4 = res.data.userList
      }
    },
    // reviewType:4 收款,5 开票
    async getReviewUserListApi5(){
      let res = await getReviewUserList({
        reviewType:5
      });
      if(res&&res.code==200){
        this.getReviewUserList5 = res.data.userList
      }
    },
    
    apply_openOpp_skqr(){
      this.openAddOpp_sr = false;
      this.form_skqr = JSON.parse(JSON.stringify(this.addForm_sr));
      this.openOpp_skqr = true;
      this.closeDialog_sr();
      this.form_skqr.fileIdList = [];
      this.form_skqr.applyType = 4;
      this.form_skqr.relType = 2;
      this.form_skqr.payerType = this.form_skqr.payType;
      this.form_skqr.applyRemark = this.form_skqr.billRemark;
      this.form_skqr.relId = this.$route.params.caseId;
      this.form_skqr.reviewUserId = 666;
    },
    apply_openAddOpp_kp(){
      this.openAddOpp_sr = false;
      this.applyForm_kp = JSON.parse(JSON.stringify(this.addForm_sr));
      this.openAddOpp_kp = true;
      this.closeDialog_sr();
      this.applyForm_kp.fileIdList = [];
      this.applyForm_kp.applyType = 5;
      this.applyForm_kp.relType = 2;
      this.applyForm_kp.type='';
      this.applyForm_kp.titleType = '';
      this.applyForm_kp.titleContent = '';
      this.applyForm_kp.applyRemark = this.applyForm_kp.billRemark;
      this.applyForm_kp.relId = this.$route.params.caseId;
      this.applyForm_kp.reviewUserId = 666;
    },
    // 财务列表
    async getList(data){
      let res = await getRealChargePage(data);
      if(res&&res.code==200){
        this.topDataAccount.invoicedAmount = res.data.invoicedAmount;
        this.topDataAccount.payAmount = res.data.payAmount;
        this.topDataAccount.predictAmount = res.data.predictAmount;
        this.topDataAccount.receivedAmount = res.data.receivedAmount;
        this.tableData_zc = res.data.payVoList.records;
        this.tableData_sr = res.data.receivedVoList.records;
        if(this.current===0){
          // 支出
          this.currentPage_zc = res.data.payVoList.current*1;
          this.pageSize_zc = res.data.payVoList.size*1;
          this.total_zc = res.data.payVoList.total*1;
          this.currentPage = this.currentPage_zc;
          this.pageSize = this.pageSize_zc;
          this.total = this.total_zc
        }
        if(this.current===1){
          // 收入
          this.currentPage_sr = res.data.receivedVoList.current*1;
          this.pageSize_sr = res.data.receivedVoList.size*1;
          this.total_sr = res.data.receivedVoList.total*1;
          this.currentPage = this.currentPage_sr*1;
          this.pageSize = this.pageSize_sr;
          this.total = this.total_sr
        };
      }
    },
    // 新增财务
    async addSaveCharge(data){
      let res = await saveCharge(data);
      if(res&&res.code==200){
        // 重新更新列表
        this.$message({
          type: 'success',
          message: '添加成功'
        });
      }
    },
    // 添加支出 / 支出详情
    saveDialog_zc(){
      this.$refs['addForm_zc'].validate(v=>{
        // 支出判断：0编辑，1新增,2查看
      if(this.zcIsAdd===1){
        // 新增
        this.addForm_zc.payDate = this.addForm_zc.payDate?moment(this.addForm_zc.payDate).format('YYYY-MM-DD HH:mm:ss'):'';
        this.addSaveCharge({
          amount:this.addForm_zc.amount,
          billRemark:this.addForm_zc.billRemark,
          chargeName:this.addForm_zc.chargeName,
          payDate:this.addForm_zc.payDate,
          payType:this.addForm_zc.payType,
          relId:this.$route.params.caseId,
          relType:2,
          type:1
        })
      };
      if(this.zcIsAdd===0){
        // 修改
        this.updateChargeApi({
          chargeId:this.addForm_zc.chargeId,
          amount:this.addForm_zc.amount,
          billRemark:this.addForm_zc.billRemark,
          chargeName:this.addForm_zc.chargeName,
          payDate:this.addForm_zc.payDate,
          payType:this.addForm_zc.payType,
          relId:this.$route.params.caseId,
          relType:2,
          type:1
        })
      };
      this.closeDialog_zc();
      })
      
    },
    closeDialog_zc(){
      this.$refs['addForm_zc'].resetFields();
      this.openAddOpp_zc = false;
      this.zcIsAdd = '';
      this.addForm_zc.chargeId = '';
      console.log('更新列表')
      // 更新列表
      this.getList({
        pageNo:1,
        pageSize:PAGE_SIZE,
        realId:this.$route.params.caseId,
        relType:2
      });
    },
    async submitApplyForm_kp(){
      await this.saveApplyApi({
        applyRemark:this.applyForm_kp.applyRemark,
        applyType:this.applyForm_kp.applyType,
        fileIdList:this.applyForm_kp.fileIdList,
        invoiceExtra:{
          chargeId:this.applyForm_kp.chargeId,
          titleContent:this.applyForm_kp.titleContent,
          titleType:this.applyForm_kp.titleType,
          type:this.applyForm_kp.type
        },
        reviewUserId:this.applyForm_kp.reviewUserId,
        relId:this.applyForm_kp.relId,
        relType:this.applyForm_kp.relType
      });
      this.closeDialog_kp();
    },
    async submitDialog_skqr(){
      if(this.form_skqr.payerDate){
        this.form_skqr.payerDate = moment(this.form_skqr.payerDate).format('YYYY-MM-DD HH:mm:ss')
      };
      await this.saveApplyApi({
        applyRemark:this.form_skqr.applyRemark,
        applyType:this.form_skqr.applyType,
        fileIdList:this.form_skqr.fileIdList,
        receiveExtra:{
          chargeId:this.form_skqr.chargeId,
          payerDate:this.form_skqr.payerDate,
          payerName:this.form_skqr.payerName,
          payerType:this.form_skqr.payerType
        },
        reviewUserId:this.form_skqr.reviewUserId,
        relId:this.form_skqr.relId,
        relType:this.form_skqr.relType
      });
      this.closeDialog_skqr();
    },
    closeDialog_skqr(){
      this.$refs['form_skqr'].resetFields();
      this.openOpp_skqr = false;
      this.form_skqr.fileIdList = [];
      this.reviewUserList = []
    },
    closeAlert(){
      console.log('closeAlert')
      this.errorText = '';
    },
    onErrorUpload(err, file, fileList){
      console.log('err:',err)
    },
    beforeRemove(file,fileList) {
      if(this.form_skqr.fileIdList.length === fileList.length){
        let currentUid = file.raw.uid;
        let currentIdx = fileList.filter((item,idx) => {
          if(item.raw.uid === currentUid){
            return idx
          }else{
            return 'none'
          }
        });
        if(currentIdx.length&&currentIdx[0]!=='none'){
          this.form_skqr.fileIdList.splice(currentIdx,1)
        }
      };
      return true
    },
    beforeRemove2(file,fileList) {
      if(this.applyForm_kp.fileIdList.length === fileList.length){
        let currentUid = file.raw.uid;
        let currentIdx = fileList.filter((item,idx) => {
          if(item.raw.uid === currentUid){
            return idx
          }else{
            return 'none'
          }
        });
        if(currentIdx.length&&currentIdx[0]!=='none'){
          this.applyForm_kp.fileIdList.splice(currentIdx,1)
        }
      };
      return true
    },
    onExceed(){
      // console.log('onExceed')
      this.errorText = '最多只能上传5个附件';
      this.upLoadIsError = true;
    },
    myUploadSubmit(item){
      this.uploadFileApi(item.file)
    },
    myUploadSubmit2(item){
      this.uploadFileApi(item.file,2)
    },
    handleChangeUpload(file, fileList){
      // console.log('file:',file)
    },
    // 文件上传
    async uploadFileApi(file,num){
      this.uploadLoading = true;
      let formData = new FormData()
      formData.append('file',file)
      if(num===2){
        formData.append("filepath",'kaipiao')
      }else{
        formData.append("filepath",'shouruqueding')
      };
      let res = await uploadFile(formData);
      if(res&&res.code==200){
        if(num===2){
          this.applyForm_kp.fileIdList.push(res.data.fileId)
        }else{
          this.form_skqr.fileIdList.push(res.data.fileId)
        };
        this.$message({
          type:'success',
          message:'上传成功'
        })
      }
      this.uploadLoading = false;
    },
    closeDialog_kp(){
      this.$refs['applyForm_kp'].resetFields();
      this.openAddOpp_kp = false;
      this.applyForm_kp.fileIdList = [];
      this.reviewUserList = []
      console.log('更新列表2')
      // 更新列表
      this.getList({
        pageNo:1,
        pageSize:PAGE_SIZE,
        realId:this.$route.params.caseId,
        relType:2
      });
    },
    
    saveDialog_sr(){
      this.$refs['addForm_sr'].validate(v=>{
        if(v){
          // 支出判断：0编辑，1新增,2查看
      if(this.scIsAdd===1){
        // 新增
        this.addSaveCharge({
          amount:this.addForm_sr.amount,
          billRemark:this.addForm_sr.billRemark,
          chargeName:this.addForm_sr.chargeName,
          payType:this.addForm_sr.payType,
          relId:this.$route.params.caseId,
          relType:2,
          type:0
        })
      };
      if(this.scIsAdd===0){
        // 修改
        this.updateChargeApi({
          chargeId:this.addForm_sr.chargeId,
          amount:this.addForm_sr.amount,
          billRemark:this.addForm_sr.billRemark,
          chargeName:this.addForm_sr.chargeName,
          payType:this.addForm_sr.payType,
          relId:this.$route.params.caseId,
          relType:2,
          type:0
        })
      };
      this.closeDialog_sr();
        }
      })
      
    },
    async updateChargeApi(data){
      let res = await updateCharge(data);
      if(res&&res.code==200){
        this.$message({
          type:"success",
          message: '编辑成功'
        })
      }
    },
    addOppBtn(){
      if(this.current===1){
        // 收入
        // 收入判断：0编辑，1新增,2查看
        this.title_sr = '添加收入';
        this.openAddOpp_sr = true;
        this.scIsAdd = 1;
      }else if(this.current===0){
        // 支出
        // 支出判断：0编辑，1新增,2查看
        this.title_zc = '添加支出';
        this.openAddOpp_zc = true;
        this.zcIsAdd=1;
      }
    },
    closeDialog_sr(){
      this.$refs['addForm_sr'].resetFields();
      this.openAddOpp_sr = false;
      this.scIsAdd = '';
      this.addForm_sr.chargeId = '';
      console.log('更新列表3')
      // 更新列表
      this.getList({
        pageNo:1,
        pageSize:PAGE_SIZE,
        realId:this.$route.params.caseId,
        relType:2
      });
    },
    // 支出
    async more(v,scope){
      switch(v){
        case '1':
          // 查看
          this.title_zc = '支出详情';
          // 支出判断：0编辑，1新增,2查看
          this.zcIsAdd = 2;
          this.openAddOpp_zc = true;
          let details = await this.getDetail({
            chargeId:scope.row.chargeId
          });
          this.addForm_zc.chargeName = details.chargeName;
          this.addForm_zc.amount = details.amount;
          this.addForm_zc.payType = details.payType;
          this.addForm_zc.payTypeName = details.payTypeName;
          this.addForm_zc.payDate = details.payDate;
          this.addForm_zc.billRemark = details.billRemark;
          this.addForm_zc.chargeId = details.chargeId;
          break;
        case '2':
          // 删除
          this.tChargeDeleteApi({
            chargeId:scope.row.chargeId
          })
          break;
      }
    },
    applyDetils(applyId){
      let routeData = this.$router.resolve({
        path:'/examine/details',
        query:{
          applyId
        }
      });
      window.open(routeData.href, '_blank');
    },
    async tChargeDeleteApi(data){
      let res = await tChargeDelete(data);
      if(res&&res.code==200){
        console.log('更新列表4')
        // 更新列表
        this.getList({
          pageNo:1,
          pageSize:PAGE_SIZE,
          realId:this.$route.params.caseId,
          relType:2
        });
        this.$message({
          type:"success",
          message:"删除成功"
        })
      }
    },
    // 收款确认、去确认、去开票、开票确认
    async saveApplyApi(data){
      let res = await saveApply(data);
      if(res&&res.code==200){
        this.$message({
          type:"success",
          message:"成功"
        })
      }
    },
    // 收入
    async more2(v,scope){
      console.log(v,scope)
      switch(v){
        case '1':
          // 查看
          this.title_sr = '收入详情';
          // 收入判断：0编辑，1新增,2查看
          // 当前收入详情ID
          this.currentDetailsId = scope.row.chargeId;
          this.scIsAdd  = 2;
          this.openAddOpp_sr = true;
          let details = await this.getDetail({
            chargeId:scope.row.chargeId
          });
          this.addForm_sr.chargeName = details.chargeName;
          this.addForm_sr.payType = details.payType;
          this.addForm_sr.payTypeName = details.payTypeName;
          this.addForm_sr.amount = details.amount;
          this.addForm_sr.billRemark = details.billRemark;
          this.addForm_sr.chargeId = details.chargeId;
          // 收款确认1 未确认 2 已确认
          this.addForm_sr.confirmStatus = details.confirmStatus;
          this.addForm_sr.confirmStatusName = details.confirmStatusName;
          // 开票确认1 未开票 2 已开票
          this.addForm_sr.invoiceStatus = details.invoiceStatus;
          this.addForm_sr.invoiceStatusName = details.invoiceStatusName;
          // 是否已申请收款
          this.addForm_sr.isApplySK = details.confirmApply?details.confirmApply.status>=1:false;
          this.addForm_sr.applyIdSK = details.confirmApply?details.confirmApply.applyId:'';
          // 是否已申请开票
          this.addForm_sr.isApplyKP = details.invoiceApply?details.invoiceApply.status>=1:false
          this.addForm_sr.applyIdKP = details.invoiceApply?details.invoiceApply.applyId:'';
          
          // 去收款确认
          if((details.confirmApply&&details.confirmApply.status===3)||!details.confirmApply){
            this.addForm_sr.confirmApplyStatus = true
          }else{
            this.addForm_sr.confirmApplyStatus = false
          };
          // 去开票
          if((details.invoiceApply&&details.invoiceApply.status===3)||!details.invoiceApply){
            this.addForm_sr.invoiceApplyStatus = true
          }else{
            this.addForm_sr.invoiceApplyStatus = false
          };
          // 收入
          if(details.confirmApply&&details.confirmApply.status===1){
            this.addForm_sr.confirmApplyStatusName = '待审批'
          }else if(details.confirmApply&&details.confirmApply.status===2){
            this.addForm_sr.confirmApplyStatusName = '通过'
          }else if(details.confirmApply&&details.confirmApply.status===3){
            this.addForm_sr.confirmApplyStatusName = '不通过'
          }else{
            this.addForm_sr.confirmApplyStatusName = false
          };
          // 开票
          if(details.invoiceApply&&details.invoiceApply.status===1){
            this.addForm_sr.invoiceApplyStatusName = '待审批'
          }else if(details.invoiceApply&&details.invoiceApply.status===2){
            this.addForm_sr.invoiceApplyStatusName = '通过'
          }else if(details.invoiceApply&&details.invoiceApply.status===3){
            this.addForm_sr.invoiceApplyStatusName = '不通过'
          }else{
            this.addForm_sr.invoiceApplyStatusName = false
          };
          break;
        case '2':
          // 删除
           this.tChargeDeleteApi({
            chargeId:scope.row.chargeId
          })
          break;
      }
    },
    async getDetail(data){
      let res = await getChargeInfo(data);
      if(res&&res.code==200){
        return res.data
      }else{
        return {}
      }
    },
    
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainFinance{
  width:100%;
  padding-bottom:30px;
  .financePart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding:30px 0;
    box-sizing: border-box;
    .financItem{
      width:25%;
      height:120px;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:last-child{
        border:none
      }
      .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:20px;
        .iconqian{
          font-size: 26px;
          color:$main-color;
          margin-right: 10px;
        }
        .titleText{
          font-size: 16px;
          color:#666
        }
      }
      .count{
        display: flex;
        align-items: center;
        justify-content: center;
        .iconqian1{
          font-size: 20px;
          color:#66C5FF;
          margin-right: 6px;
          font-weight: 800;
        }
        .countNumber{
          font-size: 20px;
          color:#66C5FF
        }
      }
    }
  }
  .financeList{
    border-top:1px solid #eee;
    width:1140px;
    box-sizing: border-box;
    margin:0 auto;
    display: flex;
    .leftMenu{
      width:120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .menuIcon{
        display: flex;
        align-items: center;
        width:100%;
        padding:20px;
        box-sizing: border-box;
        position: relative;
        .barIcon{
          display: none;
          position: absolute;
          right:14px;
          top:13px;
          width:1px;
          height:40px;
          border-right: 3px solid $main-color;
        }
        &:hover{
          cursor: pointer;
          color:$main-color;
          .iconText,.iconfont{
            cursor: pointer;
            color:$main-color;
          }
          .barIcon{
            display: block;
          }
        }
        .iconfont{
          font-size: 26px;
          color:#777;
        }
        .iconText{
          font-size: 14px;
          color:#666666;
          margin-left:10px;
        }
      }
      .menuOn{
        .barIcon{
          display: block;
        }
        .iconText,.iconfont{
          color:$main-color;
        }
      }
    }
    .rightContent{
      width:1020px;
      border-left:1px solid #eee;
      .mainColor{
        color:$main-color
      }
      .topBtn{
        display: flex;
        justify-content: flex-end;
        padding:20px 0;
        border-bottom:1px solid #eee;
      }
      .icongengduo{
        &:hover{
          cursor: pointer;
          color:$main-color;
        }
      }
      .paginationBox{
        width:100%;
        display: flex;
        justify-content: flex-end;
        margin-top:50px;
      }
    }
  }
}
.addFormBox{
  padding:30px;
  .inlineBox{
    display: flex;
    align-items: center;
    .inlineItem{
      .el-form-item__content{
        margin-left:0!important;
        display: flex;
        align-items: center;
        margin-top: 2px;
      }
    }
  }
  .btnFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin:30px auto 0 auto;
  } 
}
.sr_btnGrowBox{
  display: flex;
  align-items: center;
  width:350px;
  height:36px;
  border:1px solid #DCDFE6;
  box-sizing: border-box;
  padding:0 18px;
  border-radius:4px;
  justify-content: space-between;
  div{
    &:nth-child(2){
      color:#66C5FF;
      &:hover{
        cursor: pointer;
      };
    }
    &:nth-child(3){
      &:hover{
        cursor: pointer;
      };
    }
    &:first-child{
      color:#999;
    }
  }
  .details{
    color:#FF8600!important;
  }
  .pass{
    color:#62BE8A!important;
  }
  .error{
    color:#e4393c!important
  }
}
.applyForm_kp_inlinBox{
  display: flex;
  align-items: center;
}
.mfl0{
  .el-form-item__content{
    margin-left:0!important;
    display: flex;
    align-items: center;
  }
}
.kxje{
  span{
    margin-right: 20px;
  }
}
.applyForm_kpUpload{
  .uploadComp{
    .textLine{
      margin-top:10px;
      div{
        font-size: 12px;
        color:#666;
        height:24px;
        line-height: 24px;
        span{
          color:$main-color;
        }
      }
    }
    .errorBox{
      .el-alert{
        padding:0 8px;
      }
    }
  }
}

</style>